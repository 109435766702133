import React, { memo } from 'react';
import { DaySelect } from '~/components/DaySelect';
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState';
import { DateTimePicker } from '~/components/DateTimePicker';
import { useOptionsSourceState } from '../../shareContainers/useOptionsSourceState';
/**
 * UseOptionDaysState 的互動 UI，選擇想要統計資訊的開始時間
 *
 * Opbs, option-compare, option-price 共用
 */
const DayToSelect = memo(function DayToSelect(props) {
    const filter = useOptionDaysState.useContainer();
    const { state: sourceState } = useOptionsSourceState.useContainer();
    return filter.state.slidingWindowMode ? null : filter.state.preciseMode ? (<DateTimePicker value={filter.state.toDay?.format('YYYY-MM-DDTHH:mm')} label='結束時間' onChangeDelegate={filter.acts.setToDay} disabled={sourceState.isBackTest}/>) : (<DaySelect days={filter.props.closeDays} value={filter.state.toDay} label='結束時間' onChange={filter.acts.setToDay} disabled={sourceState.isBackTest}/>);
});
/**
 * UseOptionDaysState 的互動 UI，選擇想要統計資訊的結束時間
 *
 * 與上方差別是選項是自由的，而非 init 時，我們自動產生的收盤時間
 *
 * Opbs, option-price 共用
 */
export const SoftDayToSelect = memo(function SoftDayToSelect(props) {
    const { state, acts } = useOptionDaysState.useContainer();
    return (<DateTimePicker value={state.softToDay?.format('YYYY-MM-DDTHH:mm')} onChangeDelegate={acts.setSoftToDay} label='回溯時間' disabled={state.slidingWindowMode}/>);
});
export default DayToSelect;
