import camelcaseKeys from 'camelcase-keys';
import useSWR from 'swr-0-5-6';
import { apirc } from '~/configs/apirc';
export const useOpcontractsResource = (params) => {
    const restfulApiURL = apirc.options.opcontractsUrlGet({
        from: params?.from,
        to: params?.to,
    });
    const swr = useSWR(params?.from && params?.to ? restfulApiURL : null, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return {
        res: {
            ...swr,
            data: swr.data ? camelcaseKeys(swr.data) : null,
        },
    };
};
