import getSymbolPrice from '~/modules/options/utils/getSymbolPrice';
/**
 * 計算時間價值：
 *
 * 賣權
 * - if (價內) 時間價值 = 現價 - (履約價 - 合成價格)
 * - else 等同於現價
 
 * 買權
 * - if (價內) 時間價值 = 現價 + (履約價 - 合成價格)
 * - else 等同於現價
 */
const getTimeValue = (item, syntheticIndexPrice, isCall) => {
    const strikePrice = getSymbolPrice(item.symbol);
    let timeValue;
    if (isCall)
        timeValue =
            strikePrice < syntheticIndexPrice
                ? item.close + (strikePrice - syntheticIndexPrice)
                : item.close;
    else
        timeValue =
            strikePrice > syntheticIndexPrice
                ? item.close - (strikePrice - syntheticIndexPrice)
                : item.close;
    timeValue = Math.round(timeValue * 100) / 100;
    return { ...item, timeValue };
};
export default getTimeValue;
