import React, { memo } from 'react';
import dayAPI from '~/utils/dayAPI';
import useMedia from '~/hooks/useMedia';
import { TextField } from '@mui/material';
import { DesktopDateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
export const DateTimePicker = memo(function TimeSelect(props) {
    const { isPc } = useMedia();
    const defaultProps = {
        reduceAnimations: true,
        ampm: false,
        hideTabs: false,
        value: props.value,
        disabled: props.disabled,
        onChange: (value) => {
            props.onChangeDelegate(dayAPI(value));
        },
        mask: '____/__/__ __:__',
        label: props.label,
        renderInput: (params) => (<TextField size='small' {...params}/>),
    };
    return isPc ? (<DesktopDateTimePicker {...defaultProps}/>) : (<MobileDateTimePicker {...defaultProps}/>);
});
