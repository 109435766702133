import range from 'lodash/range';
/**
 * The static data of dayjs of open, close and break datetime for futures markets in Taiwan.
 *
 * #### The function is to rewrite depending on below code
 *
 * https://github.com/futures-ai/futures_web_react/blob/31faed7d16f2a890fabdf3046b50111f2c3b2267/src/containers/Opbs/index.js#L320-L402
 */
export const getTaiwanMarketsDays = (startDay, endDay, options) => {
    const dates = range(startDay.diff(endDay, 'day')).map((blank, index) => {
        const day = startDay.clone().add(index, 'day');
        return {
            day: day,
            openAt: day.clone().hour(8).minute(45),
            breakAt: day.clone().hour(13).minute(45),
            breakOpenAt: day.clone().hour(15).minute(0),
            closeAt: day.clone().add(1, 'day').hour(5).minute(0),
        };
    });
    return options?.weekend
        ? dates
        : dates.filter(data => data.day.weekday() !== 0 && data.day.weekday() !== 6);
};
