import useSWR from 'swr-0-5-6';
import { apirc } from '~/configs/apirc';
/** 實際上只有 open, high, low, close, volume, symbol 這6個 properties，為求方便直接 as Signalr.ValueOfOHLC */
export const useMarketPriceResource = (contract, to) => {
    const restfulApiURL = apirc.options.opQuotesURL.baseUrl;
    const seconds = to?.unix();
    const res = useSWR(seconds ? `${restfulApiURL}?contract_month=${contract}&to=${seconds}` : null, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return res.data ?? [];
};
