import dayjs from 'dayjs';
import first from 'lodash/first';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMount } from 'react-use';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
import dayAPI from '~/utils/dayAPI';
import { getTaiwanMarketsDays } from '~/utils/getTaiwanMarketsDays';
export const MINUTE_SELECTION = [5, 15, 30, 60, 120];
/** 這是選擇權最外層 (最底層參數) 的 Provider */
export const useOptionDays = () => {
    /**
     * SelectAsNow: 拿取現在的盤中區間，以有沒有超過15:00為基準 在過濾時，如果時間是在00:00-08:45之前，則拿前一日那組時間 selectAsAccumulate:
     * 開始：若今日為禮拜三且在下午，則拿自已那天禮拜三的早上，其餘拿「上禮拜三」 結束：若為假日或者是下午，則拿closeAt。
     */
    const now = dayAPI();
    const today = now.startOf('day');
    const isWeekend = [0, 6].includes(today.weekday());
    const daysRange = getTaiwanMarketsDays(today.clone().weekday(-31), today.clone().add(8, 'day'));
    const [fromDay, setFromDay] = useState();
    const [toDay, setToDay] = useState();
    const [windowLengthMinute, setWindowLengthMinute] = useState(MINUTE_SELECTION[4]);
    const [slidingWindowMode, setSlidingWindowMode] = useState(false);
    /*
     * 細部時間選取：差別在
     * 1. false -> 固定選項
     * 2.  true  -> 自己選日期、時間
     */
    const [preciseMode, setPreciseMode] = useState(false);
    /*
     * useOptionsSource.isBackTest === true 的話，就會需要用到它
     */
    const [softToDay, setSoftToDay] = useState();
    const selectAsNow = useCallback(() => {
        // 只要禮拜六開始，都一概顯示禮拜五15:00-禮拜六05:00的當下區間。
        // 因此故意將時間調整成禮拜五夜盤開盤時間15:00
        const adjustDate = isWeekend
            ? now
                .weekday(now.weekday() === 6 ? 5 : -2)
                .hour(15)
                .minute(0)
            : now;
        const adjustDateStart = adjustDate.startOf('day');
        const dayDetail = daysRange.find(datum => {
            const day = datum.day.format('MMDD');
            const isMidnight = adjustDate.hour() >= 0 && adjustDate.isBefore(adjustDate.hour(8).minute(45));
            return isMidnight
                ? day === adjustDateStart.add(-1, 'day').format('MMDD')
                : day === adjustDateStart.format('MMDD');
        });
        if (!dayDetail)
            return;
        if (adjustDate.isBefore(dayDetail.breakOpenAt)) {
            setFromDay(dayDetail.openAt);
            setToDay(dayDetail.breakAt);
            setSoftToDay(dayDetail.breakAt);
        }
        else {
            setFromDay(dayDetail.breakOpenAt);
            setToDay(dayDetail.closeAt);
            setSoftToDay(dayDetail.closeAt);
        }
    }, [daysRange, now, isWeekend]);
    // 將一般開盤&盤後開盤抓出來，時間由未來到過去排列，過濾現在以前的
    const openDays = useMemo(() => {
        return sortBy([...daysRange.map(datum => datum.openAt), ...daysRange.map(datum => datum.breakOpenAt)], datum => -datum.toDate().getTime()).filter(day => !day.isAfter(now));
    }, [daysRange, now]);
    // 將一般收盤&盤後收盤抓出來，時間由未來到過去排列，直接比照openDays數量抓對應數量
    const closeDays = useMemo(() => {
        const endDayCandidates = sortBy([...daysRange.map(datum => datum.breakAt), ...daysRange.map(datum => datum.closeAt)], datum => -datum.toDate().getTime());
        return endDayCandidates.slice(endDayCandidates.length - openDays.length);
    }, [daysRange, openDays.length]);
    const selectAsAccumulate = useCallback(() => {
        const today1500 = today.hour(15).minute(0).second(0).millisecond(0);
        const tomorrow0845 = today.add(1, 'day').hour(8).minute(45).second(0).millisecond(0);
        // 盤後ing || 凌晨時段且不是08:45 之後
        const isAfterHourTrading = !now.isBefore(today1500) ||
            (now.hour() <= 8 &&
                !(now.hour() >= tomorrow0845.hour() && now.minute() >= tomorrow0845.minute()));
        const pastWednesdays = daysRange.filter(datum => datum.day.isBefore(today.add(1, 'day')) &&
            (now.isAfter(today1500)
                ? datum.day.weekday() === 3
                : datum.day.weekday() === 3 && datum.day.format('MM/DD') !== today.format('MM/DD')));
        if (!pastWednesdays.length)
            return;
        const start = last(pastWednesdays);
        const end = daysRange.find(datum => datum.breakAt === first(closeDays) || datum.closeAt === first(closeDays));
        setFromDay(start?.openAt);
        setToDay(isAfterHourTrading || isWeekend ? end?.closeAt : end?.breakAt);
        setSoftToDay(isAfterHourTrading || isWeekend ? end?.closeAt : end?.breakAt);
    }, [daysRange, today, now, isWeekend, closeDays]);
    useMount(() => {
        selectAsAccumulate();
    });
    function setWindowTimeRange() {
        const nowTimestamp = new Date().valueOf();
        const nowNextMinute = dayjs(nowTimestamp + (60000 - (nowTimestamp % 60000)));
        setFromDay(nowNextMinute.add(-windowLengthMinute, 'minute'));
        setSoftToDay(nowNextMinute);
    }
    // 移動窗格模式：
    useEffect(() => {
        if (slidingWindowMode) {
            setWindowTimeRange();
            const timer = setInterval(setWindowTimeRange, 15000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [slidingWindowMode, windowLengthMinute]);
    return {
        props: {
            openDays,
            closeDays,
        },
        state: {
            fromDay,
            toDay,
            softToDay,
            preciseMode,
            slidingWindowMode,
            fromMinute: windowLengthMinute,
        },
        acts: {
            selectAsAccumulate,
            selectAsNow,
            setFromDay,
            setToDay,
            setSoftToDay,
            setPreciseMode,
            setSlidingWindowMode,
            setWindowLengthMinute,
        },
    };
};
/** Opbs, option-compare, option-price共用 */
export const useOptionDaysState = createContainer(useOptionDays);
