import { useState } from 'react';
import filter from 'lodash/filter';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
import { useOptionContractCodeState } from '~/modules/options/shareContainers/useOptionContractCodeState';
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState';
import { filterSocket } from '~/modules/options/utils/filterSocketUtil';
import { useParseOptionsContractMonthString } from '~/modules/options/utils/useParseOptionsContractMonthString';
import { useMarketPriceResource } from '~/modules/options/api/useMarketPriceResource';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
/**
 * 此 Provider 用來拿選擇權基本報價，一個是 socket 來的，一個是該區間最後的報價
 *
 * 資料源兩種，擇一：
 *
 * 1. SignalR 報價源: wss://realtime-quote-signalr.e01.futures-op.com/ws
 * 2. 統計 API: https://api.futures-op.com/api/options_price
 */
export const useOptionsSource = () => {
    const { state: contractState } = useOptionContractCodeState.useContainer();
    const { state: dayState } = useOptionDaysState.useContainer();
    const [isBackTest, setIsBackTest] = useState(false);
    /** 1-1 - GET 所有選擇權`報價` */
    const socket_ = useSignalrStoreValueOHLC(state => state.value);
    /** 1-2 - GET 所有選擇權 區間最後報價 */
    const quotes_ = useMarketPriceResource(contractState.month, dayState.softToDay);
    const source = isBackTest ? quotes_ : Object.values(socket_);
    /** 目前合約 */
    const contract_ = useParseOptionsContractMonthString(contractState.month);
    /** 2 - 將已取得報價過濾出`買權` */
    const callSource = filter(source, datum => filterSocket(datum, 'CALL', contract_));
    /** 3 - 將已取得報價過濾出`賣權` */
    const putSource = filter(source, datum => filterSocket(datum, 'PUT', contract_));
    return {
        state: {
            callSource,
            putSource,
            isBackTest,
        },
        acts: {
            setIsBackTest,
        },
    };
};
export const useOptionsSourceState = createContainer(useOptionsSource);
