import { useState } from 'react';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
const useOptionContractCode = () => {
    const [month, setMonth] = useState();
    return {
        state: {
            month,
        },
        acts: {
            setMonth,
        },
    };
};
/**
 * The state of conditions for filtering the data
 *
 * opbs, option-compare, option-price共用
 * */
export const useOptionContractCodeState = createContainer(useOptionContractCode);
