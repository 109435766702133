/**
 * @param datum Quote
 * @param type 選擇權商品: 買權or賣權的
 * @param parsedContract 月份、第幾週、月選還是周選
 * @returns
 */
/** 參考：https://futures-ai.com/miscellaneous/mantra-single-article?id=contract-code */
/** 過濾出符合`目前合約` 的報價源
 *
 * 數字意義：
 *  76 ======> A~L 為 CALL 各個月的ascii編碼，L轉換便是76，大於76都是PUT
 *  64 ======> A 編碼為65，所以CALL各個月的編碼扣除 64，便是月份的意思
 *
 */
export const filterSocket = (datum, type, parsedContract) => {
    if (!datum || !datum.symbol)
        return false;
    /** 例：TXO16900M2 -> M  --> 77 */
    const quoteAsciiMonth = datum.symbol.charCodeAt(8);
    /** 例：TXO16900M2 的 "O" 代表月選，若為數字數字為周選 */
    const quoteIntervalChar = parseInt(datum.symbol[2]);
    /** 77 - 76 = 1 代表1月PUT*/
    const isSameMonth = quoteAsciiMonth - 64 === parsedContract?.contractMonthAt ||
        quoteAsciiMonth - 76 === parsedContract?.contractMonthAt;
    const contractIsMonth = parsedContract?.contractType === 'month';
    if (!isSameMonth)
        return false;
    /** 小於等於 76 代表該報價為 CALL，反之 */
    const isSameType = (quoteAsciiMonth <= 76 && type === 'CALL') || (quoteAsciiMonth > 76 && type === 'PUT');
    /** 月選 O 一定不是數字 */
    /** 週選型別一定是數字，且剛好等於特定合約的周次 */
    const isSameWeek = (contractIsMonth && isNaN(quoteIntervalChar)) ||
        (!contractIsMonth &&
            typeof quoteIntervalChar === 'number' &&
            quoteIntervalChar === parsedContract?.contractWeekAt);
    return isSameType && isSameMonth && isSameWeek;
};
